﻿$standardUnit: .5rem;
@mixin flex {
  display:               -webkit-box;
  display:               -ms-flexbox;
  display:               flex;
  -webkit-box-sizing:    border-box;
  box-sizing:            border-box;
  -webkit-box-direction: normal;
}

.row {
  @include flex;
  display:            inline-flex;
  flex-direction:     row;
  //flex-flow:          row;
  -webkit-box-orient: horizontal;
}

.rowwrap {
  @include flex;
  flex-direction: row;
  flex-wrap: wrap;
  //flex-flow:          row wrap;
  -webkit-box-orient: horizontal;
}

.rowrev {
  @include flex;
  flex-direction:     row-reverse;
  //flex-flow:          row;
  -webkit-box-orient: horizontal;
}

.fixed-width12 {
  flex: 0 0 12rem;
}

.fixed-width16 {
  flex: 0 0 16rem;
}

.fixed-width20 {
  flex: 0 0 20rem;
}

.fixed-width24 {
  flex: 0 0 24rem;
}

.fixed-width28 {
  flex: 0 0 28rem;
}

.fixed-width32 {
  flex: 0 0 32rem;
}

//breakpoint	mediaQuery
//xs	'screen and (max-width: 599px)'
//sm	'screen and (min-width: 600px) and (max-width: 959px)'
//md	'screen and (min-width: 960px) and (max-width: 1279px)'
//lg	'screen and (min-width: 1280px) and (max-width: 1919px)'
//xl	'screen and (min-width: 1920px) and (max-width: 5000px)'
//lt-sm	'screen and (max-width: 599px)'
//lt-md	'screen and (max-width: 959px)'
//lt-lg	'screen and (max-width: 1279px)'
//lt-xl	'screen and (max-width: 1919px)'
//gt-xs	'screen and (min-width: 600px)'
//gt-sm	'screen and (min-width: 960px)'
//gt-md	'screen and (min-width: 1280px)'
//gt-lg	'screen and (min-width: 1920px)'

.col-lt-SM {
  /* Below 600px all flex rows should be cols*/
  @media (max-width: 600px) {
    flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    height: auto !important /*  important for sizing of row heights */
  }
}

.col-lt-MD {
  /* Below 960px all flex rows should be cols*/
  @media (max-width: 960px) {
    flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    height: auto !important; /*  important for sizing of row heights */
  }
}

.col-lt-LG {
  /* Below 1280px all flex rows should be cols*/
  @media (max-width: 1280px) {
    flex-direction: column;
    -webkit-box-orient: vertical;
    height: auto; /*  important for sizing of row heights */
  }
}

.col-lt-XL {
  /* Below 1920px all flex rows should be cols*/
  @media (max-width: 1920px) {
    flex-direction: column;
    -webkit-box-orient: vertical;
    height: auto; /*  important for sizing of row heights */
  }
}

.row-lt-SM {
  /* Below 600px all flex cols should be rows*/
  @media (max-width: 600px) {
    flex-direction: row;
    -webkit-box-orient: horizontal;
    height: auto; /*  important for sizing of row heights */
  }
}

.row-lt-MD {
  /* Below 960px all flex cols should be rows*/
  @media (max-width: 960px) {
    flex-direction: row;
    -webkit-box-orient: horizontal;
    height: auto; /*  important for sizing of row heights */
  }
}

.row-lt-LG {
  /* Below 1280px all flex cols should be rows*/
  @media (max-width: 1280px) {
    flex-direction: row;
    -webkit-box-orient: horizontal;
    height: auto; /*  important for sizing of row heights */
  }
}

.row-lt-XL {
  /* Below 1920px all flex cols should be rows*/
  @media (max-width: 1920px) {
    flex-direction: row;
    -webkit-box-orient: horizontal;
    height: auto; /*  important for sizing of row heights */
  }
}

.gap0 {
  gap: $standardUnit/2 $standardUnit/2 /* row-gap column gap */
}

.gap1 {
  gap: $standardUnit $standardUnit
}

.gap2 {
  gap: $standardUnit*2 $standardUnit*2
}

.gap3 {
  gap: $standardUnit*3 $standardUnit*3
}

.gap4 {
  gap: $standardUnit*4 $standardUnit*4
}

.col {
  @include flex;
  flex-direction:     column;
  //flex-flow:          column;
  -webkit-box-orient: vertical;
  height:             auto; /*  important for sizing of row heights */
  //margin-bottom:      20px;
}

//align flex to end
.auto-left {
  margin-left: auto;
}

//align flex to center
.center {
  align-items: center;
}

.align-left {
  align-items: flex-start;
}

.align-right {
  align-items: flex-end;
}

.self-center {
  align-self: center;
}

//align flex to start
.auto-right {
  margin-right: auto;
}





.darker-bg {
  background-color: rgba(0, 0, 0, 0.05);
}

.lighter-bg {
  background-color: rgba(255, 255, 255, 0.05);
}

.colrev {
  @include flex;
  flex-direction:     column-reverse;
  //flex-flow:          column;
  -webkit-box-orient: vertical;
  height:             auto; /*  important for sizing of row heights */
  //margin-bottom:      20px;
}

.auto {
  flex: 1 1 auto;
}

.flex50 {
  flex: 1 1 auto;
}

.space-between {
  justify-content: space-between;
}

.grow {
  flex: 1 0 auto;
}

.shrink {
  flex: 0 1 auto;
}

.basis {
  flex: 0 0 auto;
}

.occupyHalf {
  // occupy at most half of the space
  flex: 0 0 50%;
}

.textEllipse {
  white-space:   nowrap;
  overflow:      hidden;
  text-overflow: ellipsis;
}

.padTB1 {
  padding-top:    $standardUnit;
  padding-bottom: $standardUnit;
}

.padT0 {
  padding-top: $standardUnit/2;
}

.padT1 {
  padding-top: $standardUnit;
}

.padB1 {
  padding-bottom: $standardUnit;
}

.padB0 {
  padding-bottom: $standardUnit/2;
}

.transparentize-half {
  opacity: .5;
}

.transparentize-quarter {
  opacity: .75;
}

.transition-all {
  transition: all 250ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

.transition-all-long {
  transition: all 500ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

.half-sizing {
  transform: scale(0.5);
}