﻿@use '@angular/material' as mat;

@import "reset";
@import "brand-resources";
//@import 'material-design-icons-iconfont/dist/material-design-icons.css';
//@import 'typeface-roboto/index.css';
//@import 'typeface-noto-sans/index.css';


body {
  margin: 0 !important;
  //padding:                0 !important;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  //@include mat.core();

}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  //font-family: "Roboto Condensed", Helvetica Neue, sans-serif!important;
  font-size: 14px;
  font-weight: 500;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  line-height: 36px;
  white-space: nowrap;
  text-align: center;
  min-width: 64px;
  padding: 0 16px;
}


.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-subtitle, .mat-card-content {
  font-size: 14px;
}

mat-paginator {
  background-color: transparent !important;
  margin-bottom: .25rem;
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-mdc-dialog-actions {
  padding: 1rem 1.5rem !important;
  //justify-content: var(--mat-dialog-actions-alignment, start);
}


.mat-mdc-button {
  letter-spacing: 0 !important;
}

.mdc-button {
  letter-spacing: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
}

* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;

  //transition:25ms;
}

.mat-list-item {
  user-select: none;
}

.mat-slider {
  height: 24px !important; //was 48
}

.mat-slider-horizontal .mat-slider-wrapper {
  top: 11px !important; // was 23 = 48/2-1
}

.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0 !important;
}

h1, h2, h3, h4, h5, p {
  //margin: 0;
}

mat-action-row {
  margin-top: 1em;
}

mat-card-title {
  margin-bottom: 0 !important;
}

mat-card-subtitle {
  margin-bottom: 0 !important;
}

::selection {
  background: rgba(255, 167, 38, 0.39);
  text-shadow: none;
}

fieldset {
  border-color: rgba(0, 0, 0, .06);
}

fieldset > legend {
  $amount: 0.5em;
  padding-right: $amount;
  padding-left: $amount;

  text-align: center;
}


mat-chip-listbox {
  margin-left: .25rem;
}


@mixin margins {
  margin: $dim-standardUnit;
}

$roundingRadius: .5em;

@mixin round {
  border-radius: $roundingRadius;
}

@mixin round_less {
  $border-radius: $roundingRadius/2;
  border-radius: $border-radius;
}

mat-expansion-panel {
  user-select: none;
}

.mat-divider.mat-divider-inset {
  margin-left: 0 !important;
}

.mat-h2, .mat-title, .mat-typography {
  margin: 0 !important;
}

.mat-chip {
  hyphens: none;
}

.gradientCard {
  //border:     solid .1rem #D9DFF1;
  //border-radius: .5rem;
  padding: 1rem;

  //color:      rgba(255, 255, 255, 0.96);
  //background: linear-gradient(26deg, rgba(255, 255, 255, 0.9) 0%, rgba(234, 234, 234, 0.5) 100%)
}

.image-transition {
  transition: opacity 0.3s;
  transition-delay: 0.2s;

}


.ellipsisfade {
  position: relative;
  height: 3.6em; /* exactly three lines */
}

.ellipsisfade:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.animate-fadein-slowest {
  animation: fadein 1000ms;
}

.animate-fadein-long {
  animation: fadein 2000ms;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.monospaced {
  font-family: monospace, monospace;
}


.parent {
  > .opacizeOnHover {
    opacity: 0.2;

  }

  > .showOnHover {
    opacity: 0;
  }
}

.parent:hover > .opacizeOnHover {
  opacity: 1;
  -webkit-transition: opacity 125ms ease-in-out;
  -moz-transition: opacity 125ms ease-in-out;
  -ms-transition: opacity 125ms ease-in-out;
  -o-transition: opacity 125ms ease-in-out;
  transition: opacity 125ms ease-in-out;
}

.parent:hover > .showOnHover {
  opacity: 1;
  -webkit-transition: opacity 125ms ease-in-out;
  -moz-transition: opacity 125ms ease-in-out;
  -ms-transition: opacity 125ms ease-in-out;
  -o-transition: opacity 125ms ease-in-out;
  transition: opacity 125ms ease-in-out;
}


@media not all and (hover: none) {
  .opacizeParent {
    > .opacizeOnHover {
      opacity: 0.2;

    }

    > .showOnHover {
      opacity: 0;
    }
  }

  .opacizeParent:hover > .opacizeOnHover {
    opacity: 1;
    -webkit-transition: opacity 125ms ease-in-out;
    -moz-transition: opacity 125ms ease-in-out;
    -o-transition: opacity 125ms ease-in-out;
    transition: opacity 125ms ease-in-out;
  }

  .opacizeParent:hover > .showOnHover {
    opacity: 1;
    -webkit-transition: opacity 125ms ease-in-out;
    -moz-transition: opacity 125ms ease-in-out;
    -o-transition: opacity 125ms ease-in-out;
    transition: opacity 125ms ease-in-out;
  }
}


@mixin item-description {
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  @include condensed-font
}

.invert-color {
  filter: invert(1) !important
}

@mixin slight-rounding {
  border-radius: .25rem;
}

.slight-rounding {
  @include slight-rounding;
}

.clickable {
  cursor: pointer;
}

.std-rounding {
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
}

.copy-feedback {
  position: absolute;
  background-color: rgba(0, 128, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  top: -30px;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: none;
  z-index: 10;
}

.copy-feedback.show {
  opacity: 1;
  transform: translateY(0);
}
