@import "tools";

//$brand-primary:             darken(rgba(64,97,194, 0.98), 10);
$brand-primary:             darken(rgba(61, 115, 188, 0.98), 10);
$brand-secondary:           rgba(123, 138, 156, 0.95);
$brand-tertiary:            rgba(173, 191, 198, 0.45);

$brand-error:               #F44336;
$brand-error:               rgba($brand-error, .2);


$brand-warning:             #A5490B;
$brand-warning-background:  rgba($brand-warning, .2);


$brand-positive:            #0D7023;
$brand-positive-background: rgba($brand-positive, .2);


$brand-negative:            #700D0D;
$brand-negative-background: rgba($brand-negative, .2);

$main-font:                 'Roboto';
$secondary-font:            'Noto Sans';

@font-face {
  font-family: XXIIAven;
  src: url('/assets/font/XXIIAven-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: XXIIAven;
  src: url('/assets/font/XXIIAven-Bold.ttf') format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: XXIIAven;
  src: url('/assets/font/XXIIAven-Black.ttf') format("truetype");
  font-weight: 900; // black
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: XXIIAven-Bold;
  src: url('/assets/font/XXIIAven-Regular.ttf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: XXIIAven-Black;
  src: url('/assets/font/XXIIAven-Regular.ttf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: BigShouldersText-Variable;
  src: url('/assets/font/BigShouldersText-VariableFont_wght.ttf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: GangsterGrotesk-Light;
  src: url('/assets/font/GangsterGrotesk-Light.otf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: GangsterGrotesk;
  src: url('/assets/font/GangsterGrotesk-Light.otf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: Gravity Points;
  src: url('/assets/font/Gravity Points.otf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: NeutralFace;
  src: url('/assets/font/NeutralFace.otf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: OBARNE-X-Regular;
  src: url('/assets/font/OBARNE-X-Regular.otf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: FUNGISRegular;
  src: url('/assets/font/FUNGISRegular.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/font/FUNGISRegular.otf') format('opentype'); /* Safari, Android, iOS */
  font-display: swap;
}

@mixin condensed-font {
  font-family: BigShouldersText-Variable, sans-serif;
  font-weight: 400;
}

@mixin title-font {
  font-family: XXIIAven, sans-serif;
  font-weight: 900;
}

@mixin heading-font {
  font-family:    NeutralFace, sans-serif;

  $letterSpacing: .25rem;

  letter-spacing: $letterSpacing*1;

  @media (max-width: 1920px) {
    letter-spacing: $letterSpacing*.5;
  }
  @media (max-width: 1270px) {
    letter-spacing: $letterSpacing*.4;
  }

  @media (max-width: 1024px) {
    letter-spacing: $letterSpacing*0.3;
  }

  @media (max-width: 576px) {
    letter-spacing: $letterSpacing*0;
  }

  @media (max-width: 480px) {
    letter-spacing: $letterSpacing*0;
  }

  @media (max-width: 360px) {
    letter-spacing: $letterSpacing*0;
  }

}

@mixin content-font {
  font-family: XXIIAven, sans-serif;
  font-weight: 400;
}

@mixin monospaced-font {
  font-family: B612Mono-Bold, sans-serif;
}


@mixin scrollbars(
  $size: .5rem,
  $foreground-color: rgba(0,0,0,0.25),
  $background-color: rgba(0,0,0,0.15)
) {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background:    $foreground-color;
    border-radius: $size*2;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Firefox
  scrollbar-color: $foreground-color $background-color;
  scrollbar-width: thin;


}

$bgTransarency: .65;
$bgGradientBG: rgb(223, 223, 223);


.modulesBG {
  background: $bgGradientBG;
  background: linear-gradient(135deg, transparentize(rgb(86, 187, 220), $bgTransarency) 0%, rgba(255, 255, 255, 0) 100%);
}

.racksBG {
  background: $bgGradientBG;
  background: linear-gradient(135deg, transparentize(rgb(104, 191, 167), $bgTransarency) 0%, rgba(255, 255, 255, 0) 100%);
}

.patchesBG {
  background: $bgGradientBG;
  background: linear-gradient(135deg, transparentize(rgb(175, 166, 223), $bgTransarency) 0%, rgba(255, 255, 255, 0) 100%);
}

.homeBG {
  background: $bgGradientBG;
  background: linear-gradient(135deg, transparentize(rgb(148, 148, 148), $bgTransarency) 0%, rgba(255, 255, 255, 0) 100%);

}

.userBG {
  background: $bgGradientBG;
  background: linear-gradient(135deg, transparentize(rgb(229, 155, 155), $bgTransarency) 0%, rgba(255, 255, 255, 0) 100%);
}

.manualsBG {
  background: $bgGradientBG;
  background: linear-gradient(135deg, transparentize(rgb(114, 175, 166), $bgTransarency) 0%, rgba(255, 255, 255, 0) 100%);
}

.commentsBG {
  background: $bgGradientBG;
  background: linear-gradient(135deg, transparentize(rgb(104, 126, 191), $bgTransarency) 0%, rgba(255, 255, 255, 0) 100%);
}

.limit-height {
  @include scrollbars;

  max-height: 70rem;
  overflow-x: auto;
  @media (max-height: 70rem) {
    max-height:   45rem;
    overflow-x:   auto;
    margin-right: $standardUnit/2;
  }
}

.custom-scrollbar {
  @include scrollbars;
}

.scroll {
  overflow-x: auto
}

html {
  @include content-font;
  font-size:             100%;
  font-feature-settings: "liga" 0;
}


body {
  font-size: 1rem;
  //font-family: inherit;
}

h1, h2, h3, h4, h5 {
  color: rgba(0, 0, 0, 0.8);
}

h1, h2, h3, h4, h5, h6 {
  @include content-font;
}

.mat-mdc-card-title {
  @include title-font;
  font-family: 'GangsterGrotesk', ', ', sans-serif;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.7);
}

.mat-mdc-card-subtitle, p {
  font-family: 'GangsterGrotesk', ', ', sans-serif;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
}

p {
  $size:     1.5rem;
  font-size: $size;

  @media (max-width: 1270px) {
    font-size: $size*0.95;
  }

  @media (max-width: 1024px) {
    font-size: $size*0.9;
  }

  @media (max-width: 576px) {
    font-size: $size*0.85;
  }

  @media (max-width: 480px) {
    font-size: $size*0.82;
  }

  @media (max-width: 360px) {
    font-size: $size*0.75;
  }
}

h1 {
  font-size:   4rem;

  @media (max-width: 1270px) {
    font-size: 3.5rem;
  }

  @media (max-width: 1024px) {
    font-size: 3rem;
  }

  @media (max-width: 576px) {
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }

  @media (max-width: 360px) {
    font-size: 1.5rem;
  }

  @include heading-font;
  font-weight: 500;

  & .material-icons, app-hero-content-card-head-icon {
    font-size: .5em;
    color:     rgba(83, 83, 83, 0.98)
  }

}


h2 {
  font-size: 2.5rem;

  @media (max-width: 576px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
}

h3 {
  font-size: 2rem;


  @media (max-width: 576px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
}

html {
  @include scrollbars
}

.technical-item {
  font-size: .8rem;
  font-family: 'Roboto', sans-serif;
  opacity: 0.5;
}


//COLORS

$icon-color:           darken(white, 8%) opacity(86%);

$pendingContent-color: #E0E0E0; //grey 300

$color-cv-out:         #E2523C;
$color-cv-in:          #4483F2;

//palette
.colors {
  background-color: #6C941A;
  background-color: #686CF2;
  background-color: #329C31;
  background-color: #B55FD8;
  background-color: #C2781B;
  background-color: #4483F2;
  background-color: #E2523C;
  background-color: #8974E4;
  background-color: #E9437E;
  background-color: #D849B3;
}

.hot {
  color: #E2523C;
}